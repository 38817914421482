/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, {useState, useEffect} from "react";
import { Link, useSearchParams } from "react-router-dom";
import {
  Alert,
  Button,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  FormSelect,
  FormInput,
} from "shards-react";
import { accessCheck } from "../../utils/tools";
import moment from 'moment-timezone'
import { CSVLink, CSVDownload } from "react-csv";
import { WEB_PAYMENT_URL } from "../../environmentVariables";

import PageTitle from "../../components/common/PageTitle";
import Pagination from "../../components/common/Pagination";
import ActivityIndicator from "../../components/common/ActivityIndicator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from '@fortawesome/free-solid-svg-icons';

const Payments = (props) => {

  const [payments, setPayments] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(moment().format('MM'))
  const [selectedYear, setSelectedYear] = useState(moment().format('Y'))
  const [searchParams, setSearchParams] = useSearchParams();
  const [client, setClient] = useState('all');
  const [location, setLocation] = useState('all');
  const [sortBy, setSortBy] = useState('dateDesc')
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [pageSize] = useState(100)

  const csvHeader = [
    'License plate',
    '#ID',
    'Date/Time',
    'Location',
    'Type',
    'Violation fees',
    'Service fees',
    'Total',
  ]

  const generateCsvData = () => {
    let paymentsTemp = [...filterPayments()]
    let csvDataArray = paymentsTemp.map(payment => ([
      payment.license_plate,
      payment.violation_number,
      moment(payment.payment_on).format('MM/DD/Y h:mm a'),
      payment.location_name,
      payment.payment.type,
      payment.payment_on ? parseFloat(parseFloat(payment.payment.amount) - parseFloat(payment.service_fee)).toFixed(2) : parseFloat(payment.total).toFixed(2),
      payment.payment_on ? parseFloat(parseFloat(payment.service_fee)).toFixed(2) : parseFloat(payment.total).toFixed(2),
      payment.payment_on ? parseFloat(parseFloat(payment.payment.amount)).toFixed(2) : parseFloat(payment.total).toFixed(2),
    ]))
    return [csvHeader, ...csvDataArray]
  }

  const getStartAndEndDate = () => {
    let startDate = moment(selectedYear + '-' + selectedMonth + '-01 00:00:00').startOf('month')
    let endDate = moment(selectedYear + '-' + selectedMonth + '-01 00:00:00').endOf('month')
    return { startDate, endDate } 
  }

  useEffect(() => {
    let { startDate, endDate } = getStartAndEndDate()
    props.getAllPayments(startDate, endDate)
    document.title = 'Payments: EnforcePlus admin';
    !accessCheck(props.user, 'payments') && (window.location.href = '/')
    props.clearCitation()
  }, [])

  useEffect(() => {
    let { startDate, endDate } = getStartAndEndDate()
    props.getAllPayments(startDate, endDate)
  }, [selectedMonth, selectedYear])

  useEffect(() => {
    setPayments(props.payments)
  }, [props.payments])

  // useEffect(() => {
  //   if (props.addedLocation) {
  //     setAddedLocation(true);
  //     setTimeout(() => {
  //       setAddedLocation(false)
  //       props.clearAddedLocation()
  //     }, 10000);
  //   }
  //   if (props.updatedLocation) {
  //     setUpdatedLocation(true);
  //     setTimeout(() => {
  //       setUpdatedLocation(false)
  //       props.clearAddedLocation()
  //     }, 10000);
  //   }
  // }, [props.addedLocation, props.updatedLocation])

  
  
  const filterPayments = () => {
    if (payments?.length > 0) {
      let sortedPayments = [...payments];
      if (search !== '') {
        sortedPayments = sortedPayments.filter(payment => (
          payment.violation_number.toLowerCase().indexOf(search.toLowerCase()) !== -1
          || payment.license_plate.toLowerCase().indexOf(search.toLowerCase()) !== -1
          || payment.license_plate.toLowerCase().indexOf(search.toLowerCase()) !== -1
          || props.users?.find(user => user.id === payment.user)?.name?.toLowerCase().indexOf(search.toLowerCase()) !== -1
        ))
      }
      if (client !== 'all') {
        sortedPayments = sortedPayments.filter(payment => payment.client === client)
      }
      if (location !== 'all') {
        sortedPayments = sortedPayments.filter(payment => payment.location === location)
      }
      if (sortBy === 'dateDesc') {
        sortedPayments = sortedPayments.sort((a, b) => a.payment_on && b.payment_on && moment(a.payment_on).isBefore(moment(b.payment_on)) ? 1 : -1);
      }
      if (sortBy === 'dateAsc') {
        sortedPayments = sortedPayments.sort((a, b) => a.payment_on && b.payment_on && moment(a.payment_on).isBefore(moment(b.payment_on)) ? -1 : 1);
      }
      if (sortBy === 'licenseAsc') {
        sortedPayments = sortedPayments.sort((a, b) => a.license_plate.localeCompare(b.license_plate));
      }
      if (sortBy === 'licenseDesc') {
        sortedPayments = sortedPayments.sort((a, b) => b.license_plate.localeCompare(a.license_plate));
      }
      if (sortBy === 'idAsc') {
        sortedPayments = sortedPayments.sort((a, b) => a.violation_number.localeCompare(b.violation_number));
      }
      if (sortBy === 'idDesc') {
        sortedPayments = sortedPayments.sort((a, b) => b.violation_number.localeCompare(a.violation_number));
      }
      if (sortBy === 'locationAsc') {
        sortedPayments = sortedPayments.sort((a, b) => a.location_name.localeCompare(b.location_name));
      }
      if (sortBy === 'locationDesc') {
        sortedPayments = sortedPayments.sort((a, b) => b.location_name.localeCompare(a.location_name));
      }
      if (sortBy === 'violationAsc') {
        sortedPayments = sortedPayments.sort((a, b) => (a.payment_on ? parseFloat(a.payment.amount-a.service_fee) : parseFloat(a.violations_total)) > (b.payment_on ? parseFloat(b.payment.amount-b.service_fee) : parseFloat(b.violations_total)) ? 1 : -1);
      }
      if (sortBy === 'violationDesc') {
        sortedPayments = sortedPayments.sort((a, b) => (a.payment_on ? parseFloat(a.payment.amount-a.service_fee) : parseFloat(a.violations_total)) > (b.payment_on ? parseFloat(b.payment.amount-a.service_fee) : parseFloat(b.violations_total)) ? -1 : 1);
      }
      if (sortBy === 'feeAsc') {
        sortedPayments = sortedPayments.sort((a, b) => (a.payment_on ? parseFloat(a.service_fee) : parseFloat(a.violations_total)) > (b.payment_on ? parseFloat(b.service_fee) : parseFloat(b.violations_total)) ? 1 : -1);
      }
      if (sortBy === 'feeDesc') {
        sortedPayments = sortedPayments.sort((a, b) => (a.payment_on ? parseFloat(a.service_fee) : parseFloat(a.violations_total)) > (b.payment_on ? parseFloat(b.service_fee) : parseFloat(b.violations_total)) ? -1 : 1);
      }
      if (sortBy === 'totalAsc') {
        sortedPayments = sortedPayments.sort((a, b) => (a.payment_on ? parseFloat(a.payment.amount) : parseFloat(a.violations_total)) > (b.payment_on ? parseFloat(b.payment.amount) : parseFloat(b.violations_total)) ? 1 : -1);
      }
      if (sortBy === 'totalDesc') {
        sortedPayments = sortedPayments.sort((a, b) => (a.payment_on ? parseFloat(a.payment.amount) : parseFloat(a.violations_total)) > (b.payment_on ? parseFloat(b.payment.amount) : parseFloat(b.violations_total)) ? -1 : 1);
      }

      return sortedPayments.slice()
    } else {
      return []
    }
  }

  const paymentAction = (payment, action) => {
    if (action === 'view_receipt') {
      window.open(payment.payment.receipt_url)
    }
    if (action === 'view_payment') {
      window.location.href = './citation/' + payment.violation_number
    }
  }

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}

      {/* First Row of Posts */}
      <Row>
        <Col>
          <Row noGutters className="page-header py-4">
            <PageTitle xs="12" sm="4" title="Payments" className="text-sm-left mb-3" />
            <Col xs="12" sm="8" className='text-right'>
              <Row>
                <Col xs="1"></Col>
                <Col className='text-right pl-0 pr-1'>
                  <FormSelect value={selectedMonth} id="feMonth" onChange={
                    event => { 
                      setSelectedMonth(event.target.value);
                    }}>
                    <option value={'01'}>January</option>
                    <option value={'02'}>February</option>
                    <option value={'03'}>March</option>
                    <option value={'04'}>April</option>
                    <option value={'05'}>May</option>
                    <option value={'06'}>June</option>
                    <option value={'07'}>July</option>
                    <option value={'08'}>August</option>
                    <option value={'09'}>September</option>
                    <option value={'10'}>October</option>
                    <option value={'11'}>November</option>
                    <option value={'12'}>December</option>
                  </FormSelect>
                </Col>
                <Col className='text-right pl-0 pr-1'>
                  <FormSelect value={selectedYear} id="feYear" onChange={
                    event => { 
                      setSelectedYear(event.target.value);
                    }}>
                    <option value={'2023'}>2023</option>
                    <option value={'2024'}>2024</option>
                    <option value={'2025'}>2025</option>
                  </FormSelect>
                </Col>
                {props.user.type === 'super' && (
                  <Col className='text-right pl-0 pr-1'>
                    <FormSelect id="feClients" onChange={event => setClient(event.target.value)}>
                      <option value={'all'}>All clients</option>
                      {props.clients?.sort((a, b) => a.name.localeCompare(b.name)).map(client => (
                        <option value={client.id}>{client.name}</option>
                      ))}
                    </FormSelect>
                  </Col>
                )}
                <Col className='text-right pl-0 pr-1'>
                  <FormSelect value={location} id="feLocations" onChange={event => setLocation(event.target.value)}>
                    <option value={'all'}>All locations</option>
                    {props.locations?.sort((a, b) => a.name.localeCompare(b.name)).map(location => (
                      <option value={location.id}>{location.name}</option>
                    ))}
                  </FormSelect>
                </Col>
                <Col xs={1} className='text-right pl-0 pt-1'>
                  <CSVLink data={generateCsvData()} filename={"enforceplus_payments.csv"}>
                    <span style={{fontSize: 20}}>
                      <FontAwesomeIcon icon={faDownload} color={'#3D3C3E'} />
                    </span>
                  </CSVLink>    
                </Col>
              </Row>
            </Col>
          </Row>
          {props.gettingAllPayments ? (
            <Row>
              <Col className={'text-center'}>
                <ActivityIndicator />
              </Col>
            </Row>
          ) : (
            <>  
              <Card small className="mb-4 mt-4">
                <CardHeader>
                  <Row>
                    <Col xs={8}>&nbsp;</Col>
                    <Col xs={4} className='text-right pl-0'>
                      <FormInput type={'text'} className={'form-control'} placeholder={'Search'} value={search} onChange={event => setSearch(event.target.value)} />
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="p-0">
                  <table className="table mb-0">
                    <thead className="bg-none">
                      <tr>
                        <th scope="col" className="border-0">
                          <div onClick={() => setSortBy(sortBy !== 'licenseAsc' ? 'licenseAsc' : 'licenseDesc')} style={{cursor: 'pointer'}}>License plate</div>
                        </th>
                        <th scope="col" className="border-0 d-none d-md-table-cell">
                          <div onClick={() => setSortBy(sortBy !== 'idAsc' ? 'idAsc' : 'idDesc')} style={{cursor: 'pointer'}}>#ID</div>
                        </th>
                        <th scope="col" className="border-0">
                          <div onClick={() => setSortBy(sortBy !== 'dateDesc' ? 'dateDesc' : 'dateAsc')} style={{cursor: 'pointer'}}>Payment Date/Time</div>
                        </th>
                        <th scope="col" className="border-0 d-none d-md-table-cell">
                          <div onClick={() => setSortBy(sortBy !== 'locationAsc' ? 'locationAsc' : 'locationDesc')} style={{cursor: 'pointer'}}>Location</div>
                        </th>
                        <th scope="col" className="border-0">
                          <div onClick={() => setSortBy(sortBy !== 'violationAsc' ? 'violationAsc' : 'violationDesc')} style={{cursor: 'pointer'}}>Violation fees</div>
                        </th>
                        <th scope="col" className="border-0">
                          <div onClick={() => setSortBy(sortBy !== 'feeAsc' ? 'feeAsc' : 'feeDesc')} style={{cursor: 'pointer'}}>Service fee</div>
                        </th>
                        <th scope="col" className="border-0">
                          <div onClick={() => setSortBy(sortBy !== 'totalAsc' ? 'totalAsc' : 'totalDesc')} style={{cursor: 'pointer'}}>Total</div>
                        </th>
                        <th scope="col" className="border-0">
                          
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.payments && filterPayments().length > 0 && filterPayments().slice((page - 1) * pageSize, ((page - 1) * pageSize) + pageSize).map(payment => {
                        return (
                          <>
                            <tr key={payment.id}>
                              <td style={{paddingTop: 24}}>
                                {payment.license_plate}
                              </td>
                              <td style={{paddingTop: 24}} className={"d-none d-md-table-cell"}>
                                {payment.violation_number}
                              </td>
                              <td style={{paddingTop: 24}}>
                                {moment(payment.payment_on).format('MM/DD/Y h:mm a')}
                              </td>
                              <td style={{paddingTop: 24}} className={"d-none d-md-table-cell"}>
                                {payment.location_name}
                              </td>
                              <td style={{paddingTop: 24}}>
                                ${payment.payment_on ? parseFloat(parseFloat(payment.payment.amount)-parseFloat(payment.service_fee)).toFixed(2) : parseFloat(payment.total).toFixed(2)}
                              </td>
                              <td style={{paddingTop: 24}}>
                                ${payment.payment_on ? parseFloat(parseFloat(payment.service_fee)).toFixed(2) : parseFloat(0).toFixed(2)}
                              </td>
                              <td style={{paddingTop: 24}}>
                                ${payment.payment_on ? parseFloat(parseFloat(payment.payment.amount)).toFixed(2) : parseFloat(payment.total).toFixed(2)}
                              </td>
                              <td className={"text-right d-none d-md-table-cell"}>
                                <FormSelect id="feInputState" onChange={event => {paymentAction(payment, event.target.value); event.target.value = ''}}>
                                  <option value={''}>Actions</option>
                                  {payment.payment_on && payment.payment?.receipt_url && (
                                    <option value={'view_receipt'}>View receipt</option>
                                  )}
                                  <option value={'view_payment'}>View payment</option>
                                </FormSelect>
                              </td>
                            </tr>
                            <tr className="d-md-none d-xs-table-row">
                              <td className="border-top-0"></td>
                              <td colSpan={3} className={"text-right border-top-0"}>
                                <FormSelect id="feInputState" onChange={event => {paymentAction(payment, event.target.value); event.target.value = ''}}>
                                  <option value={''}>Actions</option>
                                  {payment.payment_on && payment.payment?.receipt_url && (
                                    <option value={'view_receipt'}>View receipt</option>
                                  )}
                                  <option value={'view_payment'}>View payment</option>
                                </FormSelect>
                              </td>
                            </tr>
                          </>
                        )
                      })}
                    </tbody>
                  </table>
                </CardBody>
              </Card>
              <Pagination
                data={filterPayments()}
                pageSize={pageSize}
                page={page}
                onPageChange={(newPage) => setPage(newPage)}      
              />
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default Payments;

import firebase from 'firebase';
import * as actionConstants from '../actionConstants';

const makeSecret = (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export const clearAddedClient = () => dispatch => {
  dispatch({type: actionConstants.CLEAR_ADDED_CLIENT})
}

export const getClients = () => async (dispatch, getState) => {
  dispatch({ type: actionConstants.LOADING_CLIENTS });
  let currentUser = await firebase.auth().currentUser;

  if (getState().user.user.type === 'super') {
    return firebase
    .firestore()
    .collection('clients')
    .get()
    .then(querySnapshot => {
      let clients = [];
      querySnapshot.forEach(doc => {
        clients.push({id: doc.id, ...doc.data()})
      })
      dispatch({ type: actionConstants.LOADED_CLIENTS, payload: clients})
    })
    .catch(error => console.log(error))
  } else {
    return firebase
      .firestore()
      .collection('clients')
      .doc(getState().user.user.client)
      .get()
      .then(doc => {
        dispatch({ type: actionConstants.LOADED_CLIENTS, payload: [{id: doc.id, ...doc.data()}]})
      })
      .catch(error => console.log(error))
  }
} 

  

export const getLiveClients = (userId) => (dispatch, getState) => {
  dispatch({ type: actionConstants.LOADING_CLIENTS });

  if (getState().user.user.type === 'super') {
    return firebase
    .firestore()
    .collection('clients')
    .onSnapshot(querySnapshot => {
      let clients = [];
      querySnapshot.forEach(doc => {
        clients.push({id: doc.id, ...doc.data()})
      })
      dispatch({ type: actionConstants.LOADED_CLIENTS, payload: clients})
    })
  } else {
    return firebase
      .firestore()
      .collection('clients')
      .doc(getState().user.user.client)
      .onSnapshot(doc => {
        dispatch({ type: actionConstants.LOADED_CLIENTS, payload: [{id: doc.id, ...doc.data()}]})
      })
  }
};

/**
 * Add a client
 *
 * @param data
 * @returns {function(*, *): Promise<FirebaseFirestoreTypes.DocumentReference>}
 */
 export const addClient = (data, callback) => {
  return (dispatch, getState) => {
    dispatch({type: actionConstants.ADDING_CLIENT});
    const clientData = {
      name: data.name,
      logo: data.logo,
      primary_color: data.primaryColor,
      active: data.active,
      client_secret: makeSecret(40),
      url_string: data.urlString?.length > 0 ? data.urlString.substr(0, 16) : null,
      //language: data.language,
    };
    return firebase
      .firestore()
      .collection('clients')
      .add(clientData)
      .then(doc => {
        dispatch({
          type: actionConstants.ADDED_CLIENT,
          payload: {id: doc.id, user: getState().user.user.id, ...clientData},
        });
        callback && callback({id: doc.id, user: getState().user.user.id, ...clientData})
      });
  };
};

/**
 * Edit a client
 *
 * @param data
 * @returns {function(*, *): Promise<FirebaseFirestoreTypes.DocumentReference>}
 */
export const editClient = (client, data, callback) => {
  return (dispatch, getState) => {
    dispatch({type: actionConstants.UPDATING_CLIENT});
    const clientData = {
      name: data.name,
      logo: data.logo,
      primary_color: data.primaryColor,
      active: data.active,
      url_string: data.urlString?.length > 0 ? data.urlString.substr(0, 16) : null,
      //language: data.language,
    };
    return firebase
      .firestore()
      .collection('clients')
      .doc(client.id)
      .update(clientData)
      .then(doc => {
        dispatch({
          type: actionConstants.UPDATED_CLIENT,
          payload: {id: client.id, user: getState().user.user.id, ...clientData},
        });
        callback && callback({id: client.id, user: getState().user.user.id, ...clientData})
      });
  };
};

/**
 * Edit a client integrations
 *
 * @param data
 * @returns {function(*, *): Promise<FirebaseFirestoreTypes.DocumentReference>}
 */
export const editClientIntegrations = (client, data, callback) => {
  return (dispatch, getState) => {
    dispatch({type: actionConstants.UPDATING_CLIENT});
    const clientData = {
      t2_username: data.t2Username,
      t2_password: data.t2Password,
      t2_token: data.t2Token,
    };
    return firebase
      .firestore()
      .collection('clients')
      .doc(client.id)
      .update(clientData)
      .then(doc => {
        dispatch({
          type: actionConstants.UPDATED_CLIENT,
          payload: {id: client.id, user: getState().user.user.id, ...clientData},
        });
        callback && callback({id: client.id, user: getState().user.user.id, ...clientData})
      });
  };
};

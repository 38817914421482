import firebase from 'firebase';
import * as actionConstants from '../actionConstants';
const algoliasearch = require('algoliasearch')
const client = algoliasearch('YLXZEVKXMN', '2a7ec9108c48129150285007f1eb8eee')
const chosenRoutesIndex = client.initIndex('chosenRoutes')
const visitsIndex = client.initIndex('visits')

const moment = require('moment-timezone');

export const getDailyScanTypes = (location, client, startDate, endDate, user) => async dispatch => {
  dispatch({ type: actionConstants.GETTING_DAILY_SCANS });
  // If you want an inclusive end date (fully-closed interval)
  let scans = await getScanTypesByDate(location, client, moment(startDate).startOf('day').toDate(), moment(endDate).endOf('day').toDate(), user).then(data => data)
  dispatch({ type: actionConstants.GOT_SCANS, payload: scans });
};

export const getDailyScanCounts = (location, client, startDate, endDate, user) => async dispatch => {
  dispatch({ type: actionConstants.GETTING_DAILY_SCANS });
  // If you want an inclusive end date (fully-closed interval)
  let scans = await getScanCountsByDate(location, client, moment(startDate).startOf('day').toDate(), moment(endDate).endOf('day').toDate(), user).then(data => data)
  let dailyCount = []
  let d = 1
  for (let m = moment(startDate); m.diff(endDate, 'days') <= 0; m.add(1, 'days')) {
    if (scans.find(scan => scan.day === d)) {
      dailyCount.push(scans.find(scan => scan.day === d).count)
    } else {
      dailyCount.push(0)
    }
    d++;
  }
  //let result = await Promise.all(dailyCount).then(data => data)
  dispatch({ type: actionConstants.GOT_DAILY_SCANS, payload: dailyCount });
};

export const getCitations = (location, client, startDate, endDate, user) => async dispatch => {
  dispatch({ type: actionConstants.GETTING_CITATIONS });
  let citations = await getCitationCountsByDate(location, client, moment(startDate).startOf('day').toDate(), moment(endDate).endOf('day').toDate(), user).then(data => data)
  dispatch({ type: actionConstants.GOT_CITATIONS, payload: citations });
};

export const getDisputes = (location, client, startDate, endDate, user) => async dispatch => {
  dispatch({ type: actionConstants.GETTING_DISPUTES });
  let disputes = await getDisputesByDate(location, client, moment(startDate).startOf('day').toDate(), moment(endDate).endOf('day').toDate(), user).then(data => data)
  dispatch({ type: actionConstants.GOT_DISPUTES, payload: disputes });
};

const getScanCountsByDate = async (location, client, startDate, endDate, user) => {
  let scans
  const getScanCountQuery = firebase.functions().httpsCallable('bigQueryRawSql')
  startDate = moment(startDate).toISOString()
  endDate = moment(endDate).toISOString()
  let currentTimezone = moment.tz.guess()
  let query = "SELECT EXTRACT(DAY from DATETIME(timestamp(JSON_VALUE(t.data, '$.created_at')), '" + currentTimezone + "')) as day, count(t.document_id) as count FROM `enforceplus-25667.firestore_location_activities.location_activities_raw_latest` t WHERE 1=1 AND "
  let filter = 'JSON_VALUE(t.data, "$.created_at") > "' + startDate + '" AND JSON_VALUE(t.data, "$.created_at") < "' + endDate + '"'
  
  if (location) {
    filter = filter + ' AND JSON_VALUE(t.path_params, "$.locationId") = "' + location + '"'
  }

  if (client) {
    filter = filter + ' AND JSON_VALUE(t.path_params, "$.clientId") = "' + client + '"'
  }

  if (user) {
    filter = filter + ' AND JSON_VALUE(t.data, "$.user") = "' + user + '"'
  }
  
  filter = filter + 'group by day order by day'
  scans = await getScanCountQuery({query: query + filter}).then(result => result).catch(error => console.log(error))
  let formattedScans
  if (scans?.data) {
    formattedScans = JSON.parse(scans.data)
  }
  if (formattedScans?.length > 0) {
    return formattedScans[0]
  }
  return []
}

const getScanTypesByDate = async (location, client, startDate, endDate, user) => {
  let scans
  const getScanTypeQuery = firebase.functions().httpsCallable('bigQueryRawSql')
  startDate = moment(startDate).toISOString()
  endDate = moment(endDate).toISOString()
  let query = "SELECT JSON_VALUE(t.data, '$.name') as name, count(t.document_id) as count FROM `enforceplus-25667.firestore_location_activities.location_activities_raw_latest` t WHERE 1=1 AND "
  let filter = 'JSON_VALUE(t.data, "$.created_at") > "' + startDate + '" AND JSON_VALUE(t.data, "$.created_at") < "' + endDate + '"'
  
  if (location) {
    filter = filter + ' AND JSON_VALUE(t.path_params, "$.locationId") = "' + location + '"'
  }

  if (client) {
    filter = filter + ' AND JSON_VALUE(t.path_params, "$.clientId") = "' + client + '"'
  }

  if (user) {
    filter = filter + ' AND JSON_VALUE(t.data, "$.user") = "' + user + '"'
  }

  filter = filter + ' group by name'
  scans = await getScanTypeQuery({query: query + filter}).then(result => result).catch(error => console.log(error))
  let formattedScans
  if (scans?.data) {
    formattedScans = JSON.parse(scans.data)
  }
  if (formattedScans?.length > 0) {
    return formattedScans[0]
  }
  return []

}

const getCitationsByDate = async (location, client, startDate, endDate, user) => {
  let citations
  const getAllCitationsQuery = firebase.functions().httpsCallable('bigQueryRawSql')
  startDate = moment(startDate).toISOString()
  endDate = moment(endDate).toISOString()
  let query = "SELECT t.data as data, JSON_VALUE(t.path_params, '$.clientId') as clientId, JSON_VALUE(t.path_params, '$.locationId') as locationId, t.document_id as id  FROM `firestore_tickets.tickets_raw_latest` t WHERE 1=1 AND "
  let filter = 'JSON_VALUE(t.data, "$.created_at") > "' + startDate + '" AND JSON_VALUE(t.data, "$.created_at") < "' + endDate + '"'
  
  if (location) {
    filter = filter + ' AND JSON_VALUE(t.path_params, "$.locationId") = "' + location + '"'
  }

  if (client) {
    filter = filter + ' AND JSON_VALUE(t.path_params, "$.clientId") = "' + client + '"'
  }

  if (user) {
    filter = filter + ' AND JSON_VALUE(t.data, "$.user") = "' + user + '"'
  }

  citations = await getAllCitationsQuery({query: query + filter}).then(result => result).catch(error => console.log(error))
  let formattedCitations
  let finalCitations = []
  if (citations?.data) {
    formattedCitations = JSON.parse(citations.data)
  }
  if (formattedCitations?.length > 0) {
    formattedCitations[0].forEach(row => {
      let rowData = JSON.parse(row.data)
      //if (!rowData.dispute || rowData.dispute?.status !== 'accepted') {
        finalCitations.push({...JSON.parse(row.data), client: row.clientId, location: row.locationId, id: row.id})
      //}
    })
  }
  return finalCitations
}

const getCitationCountsByDate = async (location, client, startDate, endDate, user) => {
  let citations
  const getAllCitationCountsQuery = firebase.functions().httpsCallable('bigQueryRawSql')
  startDate = moment(startDate).toISOString()
  endDate = moment(endDate).toISOString()
  let query = "SELECT JSON_VALUE(t.data, '$.status') as status, JSON_VALUE(t.data, '$.ticket_type') as ticket_type, count(JSON_VALUE(t.data, '$.status')) as count, sum(PARSE_NUMERIC(JSON_VALUE(t.data, '$.payment.amount')) - PARSE_NUMERIC(JSON_VALUE(t.data, '$.service_fee'))) as paid_total, sum(PARSE_NUMERIC(JSON_VALUE(t.data, '$.total'))) as total, sum(PARSE_NUMERIC(JSON_VALUE(t.data, '$.violations_total'))) as violations_total  FROM `firestore_tickets.tickets_raw_latest` t WHERE 1=1 AND "
  let filter = 'JSON_VALUE(t.data, "$.created_at") > "' + startDate + '" AND JSON_VALUE(t.data, "$.created_at") < "' + endDate + '"'
  
  if (location) {
    filter = filter + ' AND JSON_VALUE(t.path_params, "$.locationId") = "' + location + '"'
  }

  if (client) {
    filter = filter + ' AND JSON_VALUE(t.path_params, "$.clientId") = "' + client + '"'
  }

  if (user) {
    filter = filter + ' AND JSON_VALUE(t.data, "$.user") = "' + user + '"'
  }
  filter = filter + ' group by JSON_VALUE(t.data, "$.status"), JSON_VALUE(t.data, "$.ticket_type")'
  citations = await getAllCitationCountsQuery({query: query + filter}).then(result => result).catch(error => console.log(error))
  let formattedCitations
  let finalCitations = []
  if (citations?.data) {
    formattedCitations = JSON.parse(citations.data)
  }
  if (formattedCitations?.length > 0) {
    formattedCitations[0].forEach(row => {
      //if (!rowData.dispute || rowData.dispute?.status !== 'accepted') {
        finalCitations.push(row)
      //}
    })
  }
  return finalCitations
}

const getDisputesByDate = async (location, client, startDate, endDate) => {
  let citations
  const getAllCitationsQuery = firebase.functions().httpsCallable('bigQueryRawSql')
  startDate = moment(startDate).toISOString()
  endDate = moment(endDate).toISOString()
  let query = "SELECT t.data as data, JSON_VALUE(t.path_params, '$.clientId') as clientId, JSON_VALUE(t.path_params, '$.locationId') as locationId, t.document_id as id  FROM `firestore_tickets.tickets_raw_latest` t WHERE 1=1 AND JSON_VALUE(t.data, '$.disputed_on') is not null AND"
  let filter = '((JSON_VALUE(t.data, "$.created_at") > "' + startDate + '" AND JSON_VALUE(t.data, "$.created_at") < "' + endDate + '") OR (JSON_VALUE(t.data, "$.disputed_on") > "' + startDate + '" AND JSON_VALUE(t.data, "$.disputed_on") < "' + endDate + '"))'
  
  if (location) {
    filter = filter + ' AND JSON_VALUE(t.path_params, "$.locationId") = "' + location + '"'
  }

  if (client) {
    filter = filter + ' AND JSON_VALUE(t.path_params, "$.clientId") = "' + client + '"'
  }

  citations = await getAllCitationsQuery({query: query + filter}).then(result => result).catch(error => console.log(error))
  let formattedCitations
  let finalCitations = []
  if (citations?.data) {
    formattedCitations = JSON.parse(citations.data)
  }
  if (formattedCitations?.length > 0) {
    formattedCitations[0].forEach(row => {
      let rowData = JSON.parse(row.data)
      //if (!rowData.dispute || rowData.dispute?.status !== 'accepted') {
        finalCitations.push({...JSON.parse(row.data), client: row.clientId, location: row.locationId, id: row.id})
      //}
    })
  }
  return finalCitations
}

const getVisitorCountByDate = async (location, client, startDate, endDate) => {
  startDate = moment.utc(startDate).unix()
  endDate = moment.utc(endDate).unix()

  let filter = 'timeStamp_unix > ' + startDate + ' AND timeStamp_unix < ' + endDate

  if (location) {
    filter = filter + ' AND location:"' + location + '"'
  }

  if (client) {
    filter = filter + ' AND client:"' + client + '"'
  }

  let count = await visitsIndex.search('', {
    filters: filter
  }).then(hits => {
    console.log(hits)
    return hits.hits
  });
  return count;
};

const returnCounts = querySnapshot => {
  return querySnapshot.size
}

export const setStatusFilter = (status) => dispatch => {
  dispatch({ type: actionConstants.SET_STATUS_FILTER, payload: status });
};

// export const runCustomQuery = () => dispatch => {
//   return firebase
//     .firestore()
//     .collection('users')
//     .doc('qp34hIh5JWMMCfBaRK79e5Fn4s03')
//     .collection('activities')
//     .orderBy('license_plate', 'asc')
//     .get()
//     .then(querySnapshot => {
//       let x = 0
//       querySnapshot.docs.map((doc, idx) => {
//         console.log({id: doc.id, ...doc.data()})
//         if (doc.data().license_plate === '5VFGD') {
//           doc.ref.delete()
//         }
//       })
//       console.log('done')
//     });
// }

// export const runCustomQuery = () => dispatch => {
//   return firebase
//     .firestore()
//     .collection('vehicles')
//     .orderBy('license_plate', 'asc')
//     .get()
//     .then(querySnapshot => {
//       let x = 0
//       querySnapshot.docs.map((doc, idx) => {
//         if (idx > 0) {
//           if (doc.data().license_plate === querySnapshot.docs[idx - 1].data().license_plate && doc.data().license_plate_state === querySnapshot.docs[idx - 1].data().license_plate_state) {
//             console.log({id: doc.id, ...doc.data()})
//           }
//         }
//       })
//       console.log('done')
//     });
// }
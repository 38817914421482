import firebase from 'firebase';
import * as actionConstants from '../actionConstants';
import { generateLocationCode, generateWaypointCode } from '../utils/tools';
import moment from 'moment-timezone';

export const clearAddedLocation = () => dispatch => {
  dispatch({type: actionConstants.CLEAR_ADDED_LOCATION})
}

export const clearLocationState = () => dispatch => {
  dispatch({type: actionConstants.CLEAR_LOCATION_STATE})
}

/**
 * Get locations
 * 
 * @returns 
 */
export const getLocations = () => async (dispatch, getState) => {
  dispatch({ type: actionConstants.LOADING_LOCATIONS });
  let currentUser = await firebase.auth().currentUser;

  if (getState().user.user.type === 'super') {
    return firebase
    .firestore()
    .collectionGroup('locations')
    .get()
    .then(querySnapshot => {
      let locations = [];
      querySnapshot.forEach(doc => {
        if (doc.exists) {
          locations.push({id: doc.id, client: doc.ref.parent.parent.id, ...doc.data()})
        }
      })
      dispatch({ type: actionConstants.LOADED_LOCATIONS, payload: locations})
    })
    .catch(error => console.log(error))
  } else {
    return firebase
      .firestore()
      .collection('clients')
      .doc(getState().client.clients[0].id)
      .collection('locations')
      .get()
      .then(querySnapshot => {
        let locations = [];
        querySnapshot.forEach(doc => {
          if (doc.exists) {
            locations.push({id: doc.id, client: getState().client.clients[0].id, ...doc.data()})
          }
        })
        dispatch({ type: actionConstants.LOADED_LOCATIONS, payload: locations})
      })
      .catch(error => console.log(error))
  }
} 

/**
 * Get live locations in real-time
 * 
 * @returns 
 */
export const getLiveLocations = () => async (dispatch, getState) => {
  dispatch({ type: actionConstants.LOADING_LOCATIONS });
  let currentUser = await firebase.auth().currentUser;

  if (getState().user.user.type === 'super') {
    return firebase
    .firestore()
    .collectionGroup('locations')
    .onSnapshot(async querySnapshot => {
      let locations = [];
      querySnapshot.forEach(doc => {
        locations.push({id: doc.id, client: doc.ref.parent.parent.id, ...doc.data()})
      })
      dispatch({ type: actionConstants.LOADED_LOCATIONS, payload: locations})
    })
  } else {
    return firebase
      .firestore()
      .collection('clients')
      .doc(getState().client.clients[0].id)
      .collection('locations')
      .onSnapshot(querySnapshot => {
        let locations = [];
        querySnapshot.forEach(doc => {
          locations.push({id: doc.id, client: getState().client.clients[0].id, ...doc.data()})
        })
        dispatch({ type: actionConstants.LOADED_LOCATIONS, payload: locations})
      })
  }
} 

/**
 * Add a location
 *
 * @param data
 * {client, active, name, address1, address2, city, state, zip, lat, lon, earlyBirdDays, earlyBirdPercentage, penaltyDays, penaltyPercentage, writeOffDays, violations, officerAlertMessage, violationRules}
 * @returns {function(*, *): Promise<FirebaseFirestoreTypes.DocumentReference>}
 */
 export const addLocation = (data, callback) => {
  return (dispatch, getState) => {
    dispatch({type: actionConstants.ADDING_LOCATION});
    let earlyBird = null
    let penalty = null
    if (data.earlyBirdDays > 0 && data.earlyBirdPercentage > 0) {
      earlyBird = {
        offer_days: data.earlyBirdDays ? parseInt(data.earlyBirdDays) : 0,
        percentage: data.earlyBirdPercentage ? parseInt(data.earlyBirdPercentage) : 0
      }
    }
    if (data.penaltyDays > 0 && data.penaltyPercentage > 0) {
      penalty = {
        days: data.penaltyDays ? parseInt(data.penaltyDays) : 0,
        percentage: data.penaltyPercentage ? parseInt(data.penaltyPercentage) : 0
      }
    }
    const locationData = {
      name: data.name,
      client: data.client,
      active: data.active,
      address1: data.address1 || null,
      address2: data.address2 || null,
      city: data.city || null,
      state: data.state || null,
      zip: data.zip || null,
      lat: data.lat || null,
      lon: data.lon || null,
      early_bird: earlyBird,
      penalty: penalty,
      write_off_days: data.writeOffDays ? parseInt(data.writeOffDays) : 0,
      violation_fees: data.violations || [],
      violation_rules: data.violationRules || [],
      officer_alert_message: data.officerAlertMessage || null,
      violations_count: 0,
      service_fee: 4,
      footer_text: data.footerText || null,
      citation_term: data.citationTerm || 'Parking Violation',
      warning_term: data.warningTerm || 'Violation Warning',
      first_paragraph: data.firstParagraph || null,
      parklync_api_key: data.parklyncApiKey || null,
      parklync_location_id: data.parklyncLocationId || null,
      spothero_location_id: data.spotheroLocationId || null,
      t2_location_id: data.t2LocationId || null,
      parkpliant_username: data.parkpliantUsername || null,
      parkpliant_password: data.parkpliantPassword || null,
      iana_timezone: data.parkpliantIanaTimezone || null,
      parkpliant_days: isNaN(data.parkpliantSendDays) ? 90 : data.parkpliantSendDays,
      parkmobile_username: data.parkMobileUsername || null,
      parkmobile_password: data.parkMobilePassword || null,
      parkmobile_api_key: data.parkMobileApiKey || null,
      parkmobile_location_id: data.parkMobileLocationId || null,
      parkwhizz_location_id: data.parkWhizLocationId || null,
    };
    return firebase
      .firestore()
      .collection('clients')
      .doc(data.client)
      .collection('locations')
      .add(locationData)
      .then(doc => {
        dispatch({
          type: actionConstants.ADDED_LOCATION,
          payload: {id: doc.id, user: getState().user.user.id, ...locationData},
        });
        callback && callback({id: doc.id, user: getState().user.user.id, ...locationData})
      });
  };
};

/**
 * Edit a location
 *
 * @param data
 * {client, active, name, address1, address2, city, state, zip, lat, lon, earlyBirdDays, earlyBirdPercentage, penaltyDays, penaltyPercentage, writeOffDays, violations, officerAlertMessage, violationRules}
 * @returns {function(*, *): Promise<FirebaseFirestoreTypes.DocumentReference>}
 */
export const editLocation = (location, data, callback) => {
  return (dispatch, getState) => {
    dispatch({type: actionConstants.UPDATING_LOCATION});
    let earlyBird = null
    let penalty = null
    if (data.earlyBirdDays > 0 && data.earlyBirdPercentage > 0) {
      earlyBird = {
        offer_days: data.earlyBirdDays ? parseInt(data.earlyBirdDays) : 0,
        percentage: data.earlyBirdPercentage ? parseInt(data.earlyBirdPercentage) : 0
      }
    }
    if (data.penaltyDays > 0 && data.penaltyPercentage > 0) {
      penalty = {
        days: data.penaltyDays ? parseInt(data.penaltyDays) : 0,
        percentage: data.penaltyPercentage ? parseInt(data.penaltyPercentage) : 0
      }
    }
    const locationData = {
      name: data.name,
      client: data.client,
      active: data.active,
      address1: data.address1 || null,
      address2: data.address2 || null,
      city: data.city || null,
      state: data.state || null,
      zip: data.zip || null,
      lat: data.lat || null,
      lon: data.lon || null,
      early_bird: earlyBird,
      penalty: penalty,
      write_off_days: data.writeOffDays ? parseInt(data.writeOffDays) : 0,
      violation_fees: data.violations || [],
      violation_rules: data.violationRules || [],
      officer_alert_message: data.officerAlertMessage || null,
      footer_text: data.footerText || null,
      citation_term: data.citationTerm || 'Parking Violation',
      warning_term: data.warningTerm || 'Violation Warning',
      first_paragraph: data.firstParagraph || null,
      parklync_api_key: data.parklyncApiKey || null,
      parklync_location_id: data.parklyncLocationId || null,
      spothero_location_id: data.spotheroLocationId || null,
      t2_location_id: data.t2LocationId || null,
      parkpliant_username: data.parkpliantUsername || null,
      parkpliant_password: data.parkpliantPassword || null,
      iana_timezone: data.parkpliantIanaTimezone || null,
      parkpliant_days: isNaN(data.parkpliantSendDays) ? 90 : data.parkpliantSendDays,
      parkmobile_username: data.parkMobileUsername || null,
      parkmobile_password: data.parkMobilePassword || null,
      parkmobile_api_key: data.parkMobileApiKey || null,
      parkmobile_location_id: data.parkMobileLocationId || null,
      parkwhizz_location_id: data.parkWhizLocationId || null,
    };
    return firebase
      .firestore()
      .collection('clients')
      .doc(data.client)
      .collection('locations')
      .doc(location.id)
      .update(locationData)
      .then(doc => {
        dispatch({
          type: actionConstants.UPDATED_LOCATION,
          payload: {id: location.id, user: getState().user.user.id, ...locationData},
        });
        callback && callback({id: location.id, user: getState().user.user.id, ...locationData})
      });
  };
};

/**
 * Clone a location
 *
 * @param data
 * {client, active, name, address1, address2, city, state, zip, lat, lon, earlyBirdDays, earlyBirdPercentage, penaltyDays, penaltyPercentage, writeOffDays, violations, officerAlertMessage, violationRules}
 * @returns {function(*, *): Promise<FirebaseFirestoreTypes.DocumentReference>}
 */
export const cloneLocation = (location) => {
  return (dispatch, getState) => {
    dispatch({type: actionConstants.ADDING_LOCATION});
    const locationData = {...location};
    locationData.violations_count = 0
    if (locationData.whitelist_updated) {
      delete locationData.whitelist_updated
    }
    if (locationData.last_scan) {
      delete locationData.last_scan
    }
    if (locationData.last_scan_user) {
      delete locationData.last_scan_user
    }
    if (locationData.id) {
      delete locationData.id
    }
    locationData.name = locationData.name + ' 1'
    locationData.violations_count = 0
    return firebase
      .firestore()
      .collection('clients')
      .doc(location.client)
      .collection('locations')
      .add(locationData)
      .then(doc => {
        dispatch({
          type: actionConstants.ADDED_LOCATION,
          payload: {id: doc.id, user: getState().user.user.id, ...locationData},
        });
      });
  };
};

/**
 * Get location whitelist
 * 
 * @returns 
 */
export const getLocationWhitelist = (location) => async (dispatch, getState) => {
  dispatch({ type: actionConstants.LOADING_WHITELIST });

  return firebase
    .firestore()
    .collection('clients')
    .doc(location.client)
    .collection('locations')
    .doc(location.id)
    .collection('whitelist')
    .get()
    .then(querySnapshot => {
      let whitelist = [];
      querySnapshot.forEach(doc => {
        if (!doc.data().deleted_at) {
          whitelist.push({id: doc.id, ...doc.data()})
        }
      })
      dispatch({ type: actionConstants.LOADED_WHITELIST, payload: whitelist})
    })
    .catch(error => console.log(error))
}

/**
 * Clear whitelist
 * 
 * @param {*} location 
 * @returns 
 */
export const clearWhitelist = (location) => async (dispatch, getState) => {
  dispatch({ type: actionConstants.CLEAR_WHITELIST });
}

/**
 * Add a vehicle to the whitelist
 * 
 * @param {*} location 
 * @param {*} data 
 * @returns 
 */
export const addVehicleToWhitelist = (location, data) => {
  return async (dispatch, getState) => {
    const plateFromData = data.license_plate.toUpperCase().replace(/\s/gim, "");
    const plateStateFromData = data.license_plate_state.toUpperCase();
    const vehicleId = await getOrCreateVehicle(plateFromData, plateStateFromData, data.source).then((id) => id);
    const whitelistData = {
      "vehicle": vehicleId,
      "created_at": moment.utc().toISOString(),
      "expiry_time": data.expiry_time,
      "start_time": data.start_time,
      "license_plate": plateFromData,
      "license_plate_state": plateStateFromData,
      "space_number": data.space_number || null,
      "source": data.source || null,
      "zone": data.zone || null,
      "tenant": data.tenant || null,
    };
    firebase
      .firestore()
      .collection("vehicles")
      .doc(vehicleId)
      .collection("activities")
      .add({
        "location": location.id,
        "activity_name": "expiry",
        "created_at": moment.utc().toISOString(),
        "expiry_time": data.expiry_time,
      })
      .then((doc) => doc.id);

    firebase
      .firestore()
      .collection("clients")
      .doc(location.client)
      .collection("locations")
      .doc(location.id)
      .update({
        "whitelist_updated": moment.utc().toISOString(),
      })
      .then((doc) => doc);

    let existingWhitelistVehicle = await firebase
      .firestore()
      .collection("clients")
      .doc(location.client)
      .collection("locations")
      .doc(location.id)
      .collection("whitelist")
      .where("vehicle", "==", vehicleId)
      .get()
      .then(querySnapshot => {
        if (querySnapshot.size === 0) {
          return null
        } else {
          return querySnapshot.docs[0].id
        }
      })

    if (existingWhitelistVehicle) {
      delete whitelistData.created_at
      whitelistData.deleted_at = null
      whitelistData.deleted_by = null
      firebase
        .firestore()
        .collection("clients")
        .doc(location.client)
        .collection("locations")
        .doc(location.id)
        .collection("whitelist")
        .doc(existingWhitelistVehicle)
        .update(whitelistData)
        .then((doc) => {
          dispatch(getLocationWhitelist(location));
        });
    } else {
      firebase
        .firestore()
        .collection("clients")
        .doc(location.client)
        .collection("locations")
        .doc(location.id)
        .collection("whitelist")
        .add(whitelistData)
        .then((doc) => {
          dispatch(getLocationWhitelist(location));
        });
    }
    
  }
}

/**
 * Edit vehicle in the whitelist
 * 
 * @param {*} vehicle
 * @param {*} location 
 * @param {*} data 
 * @returns 
 */
export const editVehicleOnWhitelist = (vehicle, location, data) => {
  return async (dispatch, getState) => {
    const plateFromData = data.license_plate.toUpperCase().replace(/\s/gim, "");
    const plateStateFromData = data.license_plate_state.toUpperCase();
    let vehicleId = vehicle.vehicle
    const whitelistData = {
      "expiry_time": data.expiry_time,
      "start_time": data.start_time,
      "license_plate": plateFromData,
      "license_plate_state": plateStateFromData,
      "space_number": data.space_number || null,
      "source": data.source || null,
      "zone": data.zone || null,
    };
    firebase
      .firestore()
      .collection("vehicles")
      .doc(vehicleId)
      .collection("activities")
      .add({
        "location": location.id,
        "activity_name": "expiry",
        "created_at": moment.utc().toISOString(),
        "expiry_time": data.expiry_time,
      })
      .then((doc) => doc.id);

    firebase
      .firestore()
      .collection("clients")
      .doc(location.client)
      .collection("locations")
      .doc(location.id)
      .update({
        "whitelist_updated": moment.utc().toISOString(),
      })
      .then((doc) => doc);

    let existingWhitelistVehicle = vehicle.id  

    firebase
      .firestore()
      .collection("clients")
      .doc(location.client)
      .collection("locations")
      .doc(location.id)
      .collection("whitelist")
      .doc(existingWhitelistVehicle)
      .update(whitelistData)
      .then((doc) => {
        dispatch(getLocationWhitelist(location));
      });
    
  }
}

/**
 * Get or create vehicle from license plate and state
 * 
 * @param {*} plate 
 * @param {*} plateState
 * @param {*} source 
 * @returns 
 */
const getOrCreateVehicle = async (plate, plateState, source) => {
  return firebase
    .firestore()
    .collection("vehicles")
    .where("license_plate", "==", plate.toUpperCase())
    .where("license_plate_state", "==", plateState.toUpperCase())
    .get()
    .then((querySnapshot) => {
      if (querySnapshot.size === 0) {
        const vehicleData = {
          "license_plate": plate.toUpperCase(),
          "license_plate_state": plateState.toUpperCase(),
          "created_at": moment.utc().toISOString(),
          "source": source || null
        };
        return firebase
          .firestore()
          .collection("vehicles")
          .add(vehicleData)
          .then((doc) => {
            return doc.id;
          });
      } else {
        return querySnapshot.docs[0].id;
      }
    })
    .catch((err) => console.log(err));
};

/**
 * Get all vehicle latest activity
 * 
 * @param {*} location 
 * @param {*} vehicle 
 * @returns 
 */
export const getAllVehicleActivities = (location, vehicle) => {
  return (dispatch) => {
    dispatch({ type: actionConstants.LOADING_VEHICLE_ACTIVITIES})
    return firebase
      .firestore()
      .collection('vehicles')
      .doc(vehicle)
      .collection('activities')
      .where('location', '==', location)
      .where('activity_name', '==', 'scanned')
      .orderBy('created_at', 'desc')
      .get()
      .then(querySnapshot => {
        let activities = [];
        querySnapshot.forEach(doc => {
          activities.push({id: doc.id, ...doc.data()})
        })
        activities = activities.length > 0 ? [activities[0]] : []
        dispatch({ type: actionConstants.LOADED_VEHICLE_ACTIVITIES, payload: activities})
      })
  }
}

/**
 * Remove a vehicle from whitelist
 * 
 * @param {*} location 
 * @param {*} item 
 * @returns 
 */
export const removeFromWhitelist = (location, item) => {
  return (dispatch, getState) => {
    return firebase
      .firestore()
      .collection('clients')
      .doc(location.client)
      .collection('locations')
      .doc(location.id)
      .collection('whitelist')
      .doc(item.id)
      .update({
        deleted_at: moment.utc().toISOString(),
        deleted_by: getState().user?.user?.id
      })
      .then(() => dispatch(getLocationWhitelist(location)))
  }
}

/**
 * Add zone to location
 * 
 * @param {*} location 
 * @param {*} item 
 * @returns 
 */
export const addZoneToLocation = (location, data) => {
  let tempLocation = location
  let zones = tempLocation?.zones || []
  zones.push(data)
  return (dispatch, getState) => {
    return firebase
      .firestore()
      .collection('clients')
      .doc(location.client)
      .collection('locations')
      .doc(location.id)
      .update({
        ...location,
        zones: zones
      })
      .then(() => dispatch(getLocations()))
  }
}

/**
 * Edit location zone
 * 
 * @param {*} location 
 * @param {*} item 
 * @returns 
 */
export const editLocationZone = (location, data) => {
  let tempLocation = location
  let zones = tempLocation?.zones
  let idx = zones?.findIndex(zone => zone.id === data.id)
  zones[idx].name = data.name
  return (dispatch, getState) => {
    return firebase
      .firestore()
      .collection('clients')
      .doc(location.client)
      .collection('locations')
      .doc(location.id)
      .update({
        ...location,
        zones: zones
      })
      .then(() => dispatch(getLocations()))
  }
}

/**
 * Remove location zone
 * 
 * @param {*} location 
 * @param {*} item 
 * @returns 
 */
export const removeZoneFromLocation = (location, item) => {
  let tempLocation = location
  let zones = tempLocation?.zones
  zones = zones.filter(zone => zone.id !== item.id)
  return (dispatch, getState) => {
    firebase
      .firestore()
      .collection('clients')
      .doc(location.client)
      .collection('locations')
      .doc(location.id)
      .update({
        ...location,
        zones: zones
      })
      .then(() => dispatch(getLocations()))

    firebase
      .firestore()
      .collection('clients')
      .doc(location.client)
      .collection('locations')
      .doc(location.id)
      .collection('whitelist')
      .where('zone', '==', item.id)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          doc.ref.update({zone: null})
        })
      })
  }
}

/**
 * Import vehicles to location whitelist
 * 
 * @param {*} location 
 * @param {*} data 
 * @returns 
 */
export const importVehiclesToWhitelist = (location, data) => async dispatch => {
  const fileUrl = URL.createObjectURL(data.csvFile);

  // 2. use fetch API to read the file
  const response = await fetch(fileUrl);

  // 3. get the text from the response
  const text = await response.text();

  // 4. split the text by newline
  const lines = text.split("\n");

  // 5. map through all the lines and split each line by comma.
  let csvData = lines.map((line, idx) => {
      if (idx > 0) {
        return line.split(",")
      }
    }).filter(line => line?.length > 1);

  if (data.tenant) {
    data.tenant = data.tenant.id
  }

  csvData.map(line => {
    let lineData = {
      license_plate: line[0],
      license_plate_state: line[1],
      ...data
    }
    dispatch(addVehicleToWhitelist(location, lineData))
  })
}


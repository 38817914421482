import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col, CardHeader, Card, CardBody, FormSelect } from "shards-react";
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import { accessCheck } from "../../utils/tools";

import PageTitle from "../../components/common/PageTitle";
import SmallStats from "../../components/common/SmallStats";
import ActivityIndicator from "../../components/common/ActivityIndicator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faEllipsisH } from '@fortawesome/free-solid-svg-icons';

const moment = require('moment');

const Dashboard = (props) => {

  const [selectedMonth, setSelectedMonth] = useState(moment().format('MM'))
  const [selectedYear, setSelectedYear] = useState(moment().format('Y'))
  const [location, setLocation] = useState('all')
  const [locations, setLocations] = useState(props.locations)
  const [client, setClient] = useState(props.user.type === 'super' ? 'all' : props.clients && props.clients.length > 0 ? props.clients[0].id : null)
  const [dailyScans, setDailyScans] = useState([])
  const [citations, setCitations] = useState([])
  const [warnings, setWarnings] = useState([])
  const [scans, setScans] = useState([])
  const [disputes, setDisputes] = useState([])
  const [users, setUsers] = useState(props.users)
  const [user, setUser] = useState('all')

  const chartOptions = {
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      myScale: {
        position: 'right'
      }
    },
    
  }

  useEffect(() => {
    document.title = 'Dashboard: EnforcePlus';
    if (props.user?.type === 'tenant') {
      window.location.href = '/tenant-vehicles'
    } else {
      !accessCheck(props.user, 'dashboard') && (window.location.href = '/')
    }
    // props.runCustomQuery()
    props.getClients()
    props.getUsers()
    props.getLocations()
    props.clearCitation()
  }, [])

  useEffect(() => {
    if (location && client && selectedMonth && selectedYear && user) {
      let { startDate, endDate } = getStartAndEndDate()
      props.getCitations(location !== 'all' ? location : null, client !== 'all' ? client : null, startDate.toISOString(), endDate.toISOString(), user !== 'all' ? user : null)
      props.getDisputes(location !== 'all' ? location : null, client !== 'all' ? client : null, startDate.toISOString(), endDate.toISOString(), user !== 'all' ? user : null)
      props.getDailyScanTypes(location !== 'all' ? location : null, client !== 'all' ? client : null, startDate.toISOString(), endDate.toISOString(), user !== 'all' ? user : null)
      props.getDailyScanCounts(location !== 'all' ? location : null, client !== 'all' ? client : null, startDate.toISOString(), endDate.toISOString(), user !== 'all' ? user : null)
      if (client !== 'all' && props.locations && props.locations > 0) {
        setLocations(props.locations.filter(location => location.client === client))
      } else {
        setLocations(props.locations)
      }
    }
    
  }, [location, client, selectedMonth, selectedYear, user])

  useEffect(() => {
    setDailyScans(props.dailyScans)
  }, [props.dailyScans])

  useEffect(() => {
    setLocations(props.locations)
  }, [props.locations])

  useEffect(() => {
    setUsers(props.users)
  }, [props.users])

  useEffect(() => {
    setScans(props.scans)
  }, [props.scans])

  useEffect(() => {
    setCitations(props.citations)
  }, [props.citations])

  useEffect(() => {
    let disputesTemp = props.disputes?.length > 0 ? props.disputes?.filter(citation => citation?.disputed_on && citation?.dispute?.status === 'open') : []
    disputesTemp = disputesTemp.sort((a, b) => moment.utc(a.disputed_on).isBefore(moment.utc(b.disputed_on)) ? 1 : -1)
    setDisputes(disputesTemp)
  }, [props.disputes])

  useEffect(() => {
    setWarnings(props.warnings)
  }, [props.warnings])

  const getStartAndEndDate = () => {
    let startDate = moment(selectedYear + '-' + selectedMonth + '-01 00:00:00').startOf('month')
    let endDate = moment(selectedYear + '-' + selectedMonth + '-01 00:00:00').endOf('month')
    return { startDate, endDate } 
  }

  const getDateLabels = () => {
    let { startDate, endDate } = getStartAndEndDate()
    let start = 1
    let end = endDate.diff(startDate, 'days') + 1
    return Array(end - start + 1).fill().map((_, idx) => start + idx)
  }

  const calculateCitationDoughnut = () => {
    let paid = citations.filter(cit => cit.status === 'paid')?.map(cit => parseFloat((cit.paid_total))).reduce((total, item) => total + item, 0) ?? 0
    let unpaid = citations.filter(cit => cit.status === 'unpaid' && cit.ticket_type !== 'warning')?.map(cit => parseFloat((cit.total))).reduce((total, item) => total + item, 0) ?? 0
    let voidAmt = citations.filter(cit => cit.status === 'void')?.map(cit => parseFloat((cit.violations_total))).reduce((total, item) => total + item, 0) ?? 0
    let closed = citations.filter(cit => cit.status === 'closed')?.map(cit => parseFloat((cit.violations_total))).reduce((total, item) => total + item, 0) ?? 0
    // citations.map(citation => {
    //   if (citation.status !== 'closed') {
    //     if (citation?.payment?.amount && citation.status !== 'void') {
    //       paid = paid + parseFloat(parseFloat(citation.payment.amount) - parseFloat(citation.service_fee))
    //     } else if (!citation?.payment?.amount && citation.status !== 'void') {
    //       unpaid = unpaid + (citation.total > 0 ? parseFloat(citation.total) : 0)
    //     } else {
    //       voidAmt = voidAmt + parseFloat(citation.violations_total)
    //     }
    //   } else {
    //     closed = closed + parseFloat(citation.violations_total)
    //   }
    // })
    return {paid, unpaid, voidAmt, closed}
  }

  const calculateCitationStats = () => {
    let paid = citations.filter(cit => cit.status === 'paid')?.map(cit => (cit.count)).reduce((total, item) => total + item, 0) ?? 0
    let unpaid = citations.filter(cit => cit.status === 'unpaid' && cit.ticket_type !== 'warning')?.map(cit => (cit.count)).reduce((total, item) => total + item, 0) ?? 0
    let voidAmt = citations.filter(cit => cit.status === 'void')?.map(cit => (cit.count)).reduce((total, item) => total + item, 0) ?? 0
    let closed = citations.filter(cit => cit.status === 'closed')?.map(cit => (cit.count)).reduce((total, item) => total + item, 0) ?? 0
    let warningsCount = citations.filter(cit => cit.status === 'unpaid' && cit.ticket_type === 'warning')?.map(cit => (cit.count)).reduce((total, item) => total + item, 0) ?? 0
    // citations.map(citation => {
    //   if (citation.status !== 'closed') {
    //     if (citation?.payment?.amount && citation.status !== 'void') {
    //       paid = paid + 1
    //     } else if (citation?.payment?.amount && citation.status !== 'void') {
    //       unpaid = unpaid + 1
    //     } else {
    //       voidAmt = voidAmt + 1
    //     }
    //   } else {
    //     closed = closed + 1
    //   }
    // })
    return {paid, unpaid, voidAmt, closed, warningsCount}
  }

  const calculateScanStats = () => {
    let scan = scans.find(item => item.name === 'scan')?.count || 0
    let violation = scans.find(item => item.name === 'violation')?.count || 0
    let citation = scans.find(item => item.name === 'citation')?.count || 0
    violation = violation + citation
    return {scan, violation}
  }

  const renderDisputes = () => {
    if (props.gettingCitations) {
      return (
        <Row>
          <Col className={'text-center'}>
            <ActivityIndicator />
          </Col>
        </Row>
      )
    } 
    return disputes.map(citation => {
      return (
        <Row style={{fontSize: 11, marginBottom: 8}}>
          <Col xs={6} sm={4}>
            {citation.dispute.name}<br />
            <span style={{fontSize: 9, color: '#ADAEB2'}}>{citation.violation_number}</span>
          </Col>
          <Col xs={6} sm={4}>
            {moment(citation.disputed_on).format('MM/DD/Y')}<br />
            <span style={{fontSize: 9, color: '#E6492E'}}>Received {moment(citation.disputed_on).fromNow()}</span>
          </Col>
          <Col xs={4} sm={1}></Col>
          <Col xs={4} sm={2}>${parseFloat(citation.total).toFixed(0)}</Col>
          <Col xs={4} sm={1}><span style={{cursor: 'pointer'}} onClick={() => window.location.href = '/citation/' + citation.violation_number}><FontAwesomeIcon icon={faEllipsisH} size={10} color={'#ADAEB2'} /></span></Col>
        </Row>
      )
    })
  }

  let {paid: donutPaid, unpaid: donutUnpaid, voidAmt: donutVoid, closed: donutClosed} = calculateCitationDoughnut()
  let {paid: statsPaid, unpaid: statsUnpaid, voidAmt: statsVoid, closed: statsClosed, warningsCount: statsWarnings} = calculateCitationStats()

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle xs="12" sm="4" title="Dashboard" className="text-sm-left mb-3" />
        <Col xs="12" sm="8" className='text-right'>
          <Row>
            <Col xs="1"></Col>
            <Col className='text-right pl-0 pr-1'>
              <FormSelect value={selectedMonth} id="feMonth" onChange={
                event => { 
                  setSelectedMonth(event.target.value);
                }}>
                <option value={'01'}>January</option>
                <option value={'02'}>February</option>
                <option value={'03'}>March</option>
                <option value={'04'}>April</option>
                <option value={'05'}>May</option>
                <option value={'06'}>June</option>
                <option value={'07'}>July</option>
                <option value={'08'}>August</option>
                <option value={'09'}>September</option>
                <option value={'10'}>October</option>
                <option value={'11'}>November</option>
                <option value={'12'}>December</option>
              </FormSelect>
            </Col>
            <Col className='text-right pl-0 pr-1'>
              <FormSelect value={selectedYear} id="feYear" onChange={
                event => { 
                  setSelectedYear(event.target.value);
                }}>
                <option value={'2023'}>2023</option>
                <option value={'2024'}>2024</option>
                <option value={'2025'}>2025</option>
              </FormSelect>
            </Col>
            <Col className='text-right pl-0 pr-1'>
              {props.user.type === 'super' && (
                <FormSelect value={client} id="feClients" onChange={event => setClient(event.target.value)}>
                  <option value={'all'}>All clients</option>
                  {props.clients.sort((a, b) => a.name.localeCompare(b.name)).map(client => (
                    <option value={client.id}>{client.name}</option>
                  ))}
                </FormSelect>
              )}
            </Col>
            <Col className='text-right pl-0 pr-1'>
              <FormSelect value={location} id="feLocations" onChange={event => setLocation(event.target.value)}>
                <option value={'all'}>All locations</option>
                {locations && locations.sort((a, b) => a.name.localeCompare(b.name)).map(location => (
                  <option value={location.id}>{location.name}</option>
                ))}
              </FormSelect>
            </Col>
            <Col className='text-right pl-0'>
              <FormSelect value={user} id="feUsers" onChange={event => setUser(event.target.value)}>
                <option value={'all'}>All users</option>
                {users && users.sort((a, b) => a.name.localeCompare(b.name)).map(user => (
                  <option value={user.id}>{user.name}</option>
                ))}
              </FormSelect>
            </Col>
          </Row>
        </Col>
      </Row>

     {(!props.clients || props.clients.length === 0 || !props.locations || !props.users) ? (
       <Row>
        <Col className="mb-4">Loading...</Col>
      </Row>  
     ) : (
       <>
        <Row>
          <Col className="mb-4 align-items-stretch" lg={6} xs={12}>
            <Card className={'h-100'}>
              <CardHeader>
                <Row>
                  <Col xs="4">
                    <h6 className="m-0">Disputes</h6>
                  </Col>
                  <Col xs="8" className={'text-right'}>
                    <a href='/citations?view=disputed' style={{fontSize: 11, color: '#ADAEB2'}}>See More&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faArrowRight} size={10} /></a>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody style={{paddingTop: 12, height: 235, maxHeight: 235, overflowY: 'auto'}}>
                {props.gettingDisputes ? (
                  <Row>
                    <Col className={'text-center'}>
                      <ActivityIndicator />
                    </Col>
                  </Row>
                ) : disputes?.length > 0 ? (
                  renderDisputes()
                ) : (
                  <>
                    <span>No open disputes!</span><br />
                    <span style={{fontSize: 12}}>Great job, there are no open disputes during the selected period.</span>
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
          <Col className="mb-4 align-items-stretch" lg={6} xs={12}>
            <Card className={'h-100'}>
              <CardHeader>
                <Row>
                  <Col xs="4">
                    <h6 className="m-0">Citations</h6>
                  </Col>
                  <Col xs="8" className={'text-right'}>
                    <a href='/citations' style={{fontSize: 11, color: '#ADAEB2'}}>See More&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faArrowRight} size={10} /></a>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody style={{padding: 0, paddingBottom: 32}}>
                {props.gettingCitations ? (
                  <Row>
                    <Col className={'text-center'}>
                      <ActivityIndicator />
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col sm={6} xs={12}>
                      <div style={{paddingLeft: 8}}>
                        <Chart
                          type='doughnut'
                          datasetIdKey='id'
                          options={{
                            responsive: true,
                            cutout: '60%',
                            plugins: {
                              legend: {
                                position: 'top',
                              },
                              title: {
                                display: false,
                              }
                            }
                          }}
                          data={{
                            datasets: [
                              {
                                id: 1,
                                label: '',
                                data: [donutPaid, donutUnpaid, donutVoid, donutClosed],
                                backgroundColor: ['#21AA73', '#E6492E', '#000000', '#0000FF'],
                              },
                            ],
                          }}
                        />
                      </div>
                      
                    </Col>
                    <Col sm={6} xs={12}>
                      <div style={{paddingRight: 16, paddingTop: 28, borderBottom: '1px solid #EAEDF3', paddingBottom: 16}}> 
                        <div style={{width: '100%', textAlign: 'right'}}>
                          <span style={{fontSize: 40, lineHeight: '40px'}}>${(donutPaid + donutUnpaid + donutVoid + donutClosed).toFixed(2)}</span>
                        </div>
                        <div style={{width: '100%', textAlign: 'right'}}>
                          <span style={{fontSize: 9, lineHeight: '10px'}}>ISSUED THIS PERIOD</span>
                        </div>
                      </div>
                      <div style={{paddingRight: 16, paddingTop: 16}}> 
                        <Row>
                          <Col xs={1}>
                            <span style={{fontSize: 40, lineHeight: '18px', color: 'green'}}>&bull;</span>
                          </Col>
                          <Col xs={5}>
                            <span style={{fontSize: 12, lineHeight: '10px'}}>PAID</span>
                          </Col>
                          <Col xs={5} className={'text-right pr-0 pl-0'}>${(donutPaid).toFixed(2)}</Col>
                        </Row>
                        <Row>
                          <Col xs={1}>
                            <span style={{fontSize: 40, lineHeight: '18px', color: '#E6492E'}}>&bull;</span>
                          </Col>
                          <Col xs={5}>
                            <span style={{fontSize: 12, lineHeight: '10px'}}>UNPAID</span>
                          </Col>
                          <Col xs={5} className={'text-right pr-0 pl-0'}>${(donutUnpaid).toFixed(2)}</Col>
                        </Row>
                        <Row>
                          <Col xs={1}>
                            <span style={{fontSize: 40, lineHeight: '18px', color: '#000000'}}>&bull;</span>
                          </Col>
                          <Col xs={5}>
                            <span style={{fontSize: 12, lineHeight: '10px'}}>VOID</span>
                          </Col>
                          <Col xs={5} className={'text-right pr-0 pl-0'}>${(donutVoid).toFixed(2)}</Col>
                        </Row>
                        <Row>
                          <Col xs={1}>
                            <span style={{fontSize: 40, lineHeight: '18px', color: '#0000FF'}}>&bull;</span>
                          </Col>
                          <Col xs={5}>
                            <span style={{fontSize: 12, lineHeight: '10px'}}>CLOSED</span>
                          </Col>
                          <Col xs={5} className={'text-right pr-0 pl-0'}>${(donutClosed).toFixed(2)}</Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        {props.gettingDailyScans ? (
          <Row style={{height: 140}}>
            <Col className={'text-center mb-4'} style={{height: 140, paddingTop: 60}}>
              <ActivityIndicator />
            </Col>
          </Row>
        ) : (
          <Row>
            <Col className="col-lg mb-4" md="6" sm="6">
              <Card className={'h-100'}>
                <CardHeader className={'text-center'}>
                  <h6 className="m-0" style={{fontSize: 11}}>PLATES SCANNED</h6>
                </CardHeader>
                <CardBody className={'text-center'} style={{paddingTop: 0}}>
                  <span style={{fontSize: 32}}>{calculateScanStats().scan + calculateScanStats().violation}</span>
                </CardBody>
              </Card>
            </Col>
            <Col className="col-lg mb-4" md="6" sm="6">
              <Card className={'h-100'}>
                <CardHeader className={'text-center'}>
                  <h6 className="m-0" style={{fontSize: 11}}>VIOLATIONS DETECTED</h6>
                </CardHeader>
                <CardBody className={'text-center'} style={{paddingTop: 0}}>
                  <span style={{fontSize: 32}}>{calculateScanStats().violation}</span>
                </CardBody>
              </Card>
            </Col>
            <Col className="col-lg mb-4" md="6" sm="6">
              <Card className={'h-100'}>
                <CardHeader className={'text-center'}>
                  <h6 className="m-0" style={{fontSize: 11}}>WARNINGS ISSUED</h6>
                </CardHeader>
                <CardBody className={'text-center'} style={{paddingTop: 0}}>
                  <span style={{fontSize: 32}}>{statsWarnings}</span>
                </CardBody>
              </Card>
            </Col>
            <Col className="col-lg mb-4" md="6" sm="6">
              <Card className={'h-100'}>
                <CardHeader className={'text-center'}>
                  <h6 className="m-0" style={{fontSize: 11}}>CITATIONS ISSUED</h6>
                </CardHeader>
                <CardBody className={'text-center'} style={{paddingTop: 0}}>
                  <span style={{fontSize: 32}}>{statsPaid + statsUnpaid + statsVoid + statsClosed}</span>
                </CardBody>
              </Card>
            </Col>
            <Col className="col-lg mb-4" md="6" sm="6">
              <Card className={'h-100'}>
                <CardHeader className={'text-center'}>
                  <h6 className="m-0" style={{fontSize: 11}}>CITATIONS PAID</h6>
                </CardHeader>
                <CardBody className={'text-center'} style={{paddingTop: 0}}>
                  <span style={{fontSize: 32}}>{statsPaid}</span>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
        {/* <Row>
          <Col className="col-lg mb-4" md="6" sm="6">
            <SmallStats
              id={`small-stats-0`}
              variation="1"
              chartData={
                [
                  {
                    id: 1,
                    fill: true,
                    borderWidth: 1.5,
                    backgroundColor: "rgba(0, 184, 216, 0.1)",
                    borderColor: "rgb(0, 184, 216)",
                    data: realTimeCount
                  }
                ]
              }
              chartLabels={Array.from(Array(12).keys()).map(key => 12 - (key + 1)).map(num => moment().subtract(num * 15, 'minutes').format("h:mm a"))}
              label={"Realtime users"}
            />
          </Col>
          <Col className="col-lg mb-4" md="6" sm="6">
            <SmallStats
              id={`small-stats-1`}
              variation="1"
              chartData={
                [
                  {
                    id: 2,
                    fill: true,
                    borderWidth: 1.5,
                    backgroundColor: "rgba(23,198,113,0.1)",
                    borderColor: "rgb(23,198,113)",
                    data: totalVisitsCount
                  }
                ]
              }
              chartLabels={Array.from(Array(12).keys()).map(key => 12 - (key + 1)).map(num => moment().subtract(num, 'months').format("MMM"))}
              label={"Total visits"}
            />
          </Col>
        </Row> */}
        <Row>
          <Col className="mb-4">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs="12" sm="4">
                    <h6 className="m-0">Daily plate scans</h6>
                  </Col>
                  
                </Row>
              </CardHeader>
              <CardBody>
                {props.gettingDailyScans ? (
                  <Row style={{height: 140}}>
                    <Col className={'text-center mb-4'} style={{height: 140, paddingTop: 60}}>
                      <ActivityIndicator />
                    </Col>
                  </Row>
                ) : (
                  <Chart
                    type='bar'
                    datasetIdKey='id'
                    options={chartOptions}
                    data={{
                      labels: getDateLabels(),
                      datasets: [
                        {
                          id: 1,
                          label: '',
                          data: dailyScans,
                          backgroundColor: '#21AA73',
                          gridLines: false,
                        },
                      ],
                    }}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
       </>
     )} 
    </Container>
  )
};

export default Dashboard;
